<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";

@import "~@/assets/css/enroll-form.css";
</style>
<style scoped>
.pay-money {
  height: unset;
}
</style>
<style type="text/css" scoped>
/deep/ .selectShow [readOnly] {
  border: none;
}
.selectShow :disabled {
  border: none;
}
.custom-file-label::after {
  display: none;
}
.el-form-item {
  margin-bottom: unset;
  width: 100%;
}

.msgVal {
  padding-left: 10px;
  padding-right: 10px;
}

.msgtextarea .msgVal {
  display: block;
}

/deep/.el-input__inner {
  background-color: #fff9eb;
  width: 100%;
  height: 30px;
  background: unset;
}

/deep/ .el-textarea__inner {
  background-color: #fff9eb;
}

/deep/.el-form-item__content {
  line-height: inherit;
}

.el-form-item--small .el-form-item__error {
  margin-top: 2px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

/deep/.el-form-item__error {
  position: absolute !important;
  top: 20%;
  left: 60%;
  text-align: center;
  width: 120px;
  height: 21px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 21px;
  z-index: 99;
}

.el-radio {
  margin-bottom: 0;
}
.custom-file-label::after {
  display: none;
}
.msgLabel {
  height: 45px;
}
.personPic {
  height: 270px;
}
.pay-box img {
  width: 56px;
  height: 56px;
}
tbody tr th {
  text-align: center;
}
.table {
  font-size: 14px !important;
}
.canEdit {
  border: 1px solid red;
  margin: 1px;
}
/deep/ .xlbtn {
  display: none;
}
.print {
  text-align: center;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import FormHeader from "@/views/pages/examinee/form-header.vue";
import draggable from "vuedraggable";
import examForm from "@/components/form/examForm";

import registerInfo from "@/components/examInfo/registerInfo";
import freeExam from "@/components/examInfo/freeExam";
import statusInfo from "@/components/examInfo/statusInfo";

// import $ from "jquery"
import {
  getExamInfo,
  examApply,
  examEdit,
  getUserExamOne,
  getUserBmk,
  getUserExamFree,
  getbmkskm,
  userSpecial,
} from "@/api/examination.js";
import { getUserInfo } from "@/libs/common";
/**
 * 报名信息提交
 */
export default {
  components: {
    Layout,
    PageHeader,
    FormHeader,
    draggable,
    examForm,
    registerInfo,
    freeExam,
    statusInfo,
  },
  data() {
    return {
      title: "报名信息提交",
      items: [
        {
          text: "考试报名",
        },
        {
          text: "报名详情",
          active: true,
        },
      ],
      userInfo: {},
      query: {},
      examInfo: {},
      form: {
        gznxzm: "",
        xlzm: "",
        qtzm: "",
      },
      readOnly: true,
      msData: {},
      cwzddmList: [],
      bmkmList: [],
      specialData: {}, //特殊库信息
    };
  },
  methods: {
    GoApplyPrint() {
      let ksbmbh = this.form.ksbmbh;
      let bmh = this.form.bmh;
      this.$router.push({
        path: "/applyPrint",
        query: {
          ksbmbh: ksbmbh,
          bmh: bmh,
        },
      });
    },
    save() {
      let _this = this;
      if (_this.form.bmh) {
        examEdit(_this.form).then((res) => {
          if (res.status) {
            _this.$router.push("./record");
            _this.$message({ type: "success", message: res.message });
          }
        });
      } else {
        examApply(_this.form).then((res) => {
          if (res.status) {
            _this.$router.push("./record");
          }
        });
      }
    },
    submitFrom() {
      this.form.sfzstj = true;
      examEdit(this.form).then((res) => {
        if (res.status) {
          this.$router.push("./record");
          this.$message({ type: "success", message: res.message });
        }
      });
    },

    getExamInfo() {
      getExamInfo(this.query.ksbmbh).then((res) => {
        if (res.status) {
          this.examInfo = res.data;
          if (this.examInfo.sfqytsmdk) {
            this.getSpecial();
          } else {
            this.init(this.query.bmh);
          }
        }
      });
    },

    getUserInfo() {
      getUserInfo().then((data) => {
        this.userInfo = data;
        return;
      });
    },

    getmsData() {
      getUserExamFree().then((res) => {
        if (res.status) {
          this.msData = res.data.filter((v) => {
            return v.ksbmbh == this.examInfo.ksbmbh;
          })[0];
        }
      });
    },
    init(bmh) {
      getUserExamOne(bmh).then((res) => {
        if (res.status) {
          let data = res.data[0] || "";
          if (data) {
            if (data.mssqzt != -1) {
              this.getmsData();
            }
            if (this.specialData.json) {
              let obj = JSON.parse(this.specialData.json);
              for (const key in obj) {
                if (!data[key]) {
                  data[key] = obj[key];
                }
              }
            }
            this.form = data;
            // if (this.examInfo.bmkbm) {
              getUserBmk(this.query.ksbmbh).then((res) => {
                if (res.status) {
                  let list = res.data[res.data.length - 1] || {};
                  for (const key in list) {
                    if (!data[key]) {
                      data[key] = list[key];
                    }
                  }
                  this.form = Object.assign({}, data);
                }
              });
            // } else {
            //   this.form = data;
            // }
            //判断是否可以修改
            if (
              data.cwzddm &&
              data.sfyxxg &&
              !data.sfzstj &&
              data.zgshzt == 2
            ) {
              if (new Date().getTime() < new Date(data.yxxgsj).getTime()) {
                this.cwzddmList = data.cwzddm.split("、");
              }
            }
          }
        }
      });
    },
    //查询报名库
    getBmk() {
      getUserBmk(this.query.ksbmbh).then((res) => {
        if (res.status) {
          let obj = res.data[0] || {};
          for (const key in obj) {
            if (!this.form[key]) {
              this.form[key] = obj[key];
            }
          }
        }
      });
    },
    //查询特殊库
    getSpecial() {
      userSpecial().then((res) => {
        if (res.status) {
          this.init(this.query.bmh);
          let list = res.data;
          if (list && list.length) {
            let tsData = list.filter((v) => {
              return v.ksbmbh == this.examInfo.ksbmbh;
            })[0];
            if (tsData) {
              this.specialData = tsData;
            }
          }
        }
      });
    },
    GoBack(){
      this.$router.back()
    }
  },
  created() {
    this.query = this.$route.query;
    this.getExamInfo();
    getbmkskm(this.query.bmh).then((res) => {
      if (res.status) {
        this.bmkmList = res.data;
      }
    });
  },
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="">
      <div class="row" id="applyInfo">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div
                class="page-title-box form-red-title d-flex align-items-center justify-content-center"
              >
                {{ examInfo.dymc }}
              </div>
              <div>
                <div
                  class="pay-money d-flex align-items-center justify-content-between mb-3"
                >
                  <div>
                    <span>基本信息</span>
                  </div>
                  <div>
                    <span class="ml-3" style="font-size: 14px">
                      报名时间：{{ form.createTime }}
                    </span>
                    <button
                      v-if="
                        (form.zgshzt != 0 && form.zgshzt != null) || form.sfzstj
                      "
                      @click="GoApplyPrint()"
                      class="btn btn-sm btn-info"
                    >
                      打印报名表
                    </button>
                    <button
                      @click="GoBack()"
                      class="btn btn-sm btn-info ml-3"
                    >
                      返回
                    </button>
                  </div>
                </div>
                <examForm
                  v-model="form"
                  :examInfo="examInfo"
                  :bmh="form.bmh"
                  :ksbmbh="query.ksbmbh"
                  :readOnly="readOnly"
                  :kxgList="cwzddmList"
                  :specialData="specialData"
                  :isShowYJ="true"
                  @save="save"
                  @submit="submitFrom"
                ></examForm>
              </div>
              <div class="mt-3" v-if="form.kmxzfs == 1 && examInfo.kslxmc != '其他申请报名'">
                <div class="pay-money">报考信息</div>
                <div class="mt-3">
                  <div class="outerCotent person-form">
                    <registerInfo v-model="form"></registerInfo>
                  </div>
                </div>
              </div>
              <div
                class="mt-3"
                v-if="form.mssqzt != '-1' && form.mssqzt != null"
              >
                <div class="pay-money">免试信息</div>
                <div class="mt-3 outerCotent person-form">
                  <freeExam v-model="form"></freeExam>
                </div>
              </div>
              <div class="mt-3">
                <div class="pay-money">报名状态</div>
                <div class="mt-3">
                  <statusInfo v-model="form" :examInfo="examInfo"></statusInfo>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
